import { Grid } from "@mui/material";
import CustomInfoTooltip from "@components/CustomInfoTooltip/CustomInfoTooltip";
import displayPrice from "@utils/displayPrice";
import { kebabCase } from "lodash";
import { ReactNode } from "react";

const OrderLine = ({
  label,
  lineTotal,
  typography,
  points,
  currency,
  displayNegative = false,
  info,
  displayZeroAsFree = false,
  bold = false,
}: {
  label: string;
  lineTotal?: number | bigint;
  currency?: string;
  typography: string;
  points?: string;
  displayNegative?: boolean;
  info?: ReactNode;
  displayZeroAsFree?: boolean;
  bold?: boolean;
}) => {
  return (
    <Grid item container>
      <Grid item xs sx={{ display: "flex", fontWeight: bold ? "800" : "initial" }} typography={typography}>
        {label}
        {info && <CustomInfoTooltip data-testid="custom-info-tooltip" content={info} />}
      </Grid>
      {points && (
        <Grid item typography={typography}>
          ({points} points)
        </Grid>
      )}
      {lineTotal !== undefined && (
        <Grid
          item
          typography={typography}
          sx={{ fontWeight: bold ? "800" : "initial" }}
          data-testid={`line-total-${kebabCase(label)}`}
        >
          {displayPrice(displayNegative ? -lineTotal : lineTotal, { currency, displayZeroAsFree })}
        </Grid>
      )}
    </Grid>
  );
};

export default OrderLine;
